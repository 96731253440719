<template>
  <td>
    <div class="flex justify-between items-center">
      <!-- Название отчета / Name report -->
      <span :class="['pl-4', 'leading-10', 'font-bold', 'text-sm', 'transition-colors', 'ease-in-out', 'duration-200', { ...getClassesForTableName(props.report) }]">
        {{ props.report.name }}
      </span>

      <!-- Скачать отчет / Download report -->
      <div v-if="props.report.is_separately" class="h-full mr-2" @click.stop>
        <el-dropdown trigger="click" @command="downloadReport">
          <span>
            <LoadIcon class="download-separatly el-dropdown-link" />
          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item command="excel"> {{ $t('save') }} Excel </el-dropdown-item>
              <el-dropdown-item command="word"> {{ $t('save') }} Word </el-dropdown-item>
              <el-dropdown-item command="pdf"> {{ $t('save') }} PDF </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>
  </td>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue'
import LoadIcon from '../icons/LoadIcon.vue'

const props = defineProps({
  report: {
    type: Object,
    default: () => ({})
  },
  reportErrors: {
    type: Array,
    default: () => []
  },
  reportForm: {
    type: Object,
    default: () => ({})
  }
})

const getClassesForTableName = table => {
  if (!props.reportForm.datePicker) return
  const hasError = props.reportErrors.includes(table.id + props.reportForm.unitsId + props.reportForm?.datePicker.join(''))
  const disableChart = props.reportForm.object === 'group' && table.key === 'chart'

  return {
    'text-notify': hasError,
    'text-headerText': !hasError,
    disabled: disableChart
  }
}
const emit = defineEmits(['download-separately'])
const downloadReport = command => {
  emit('download-separately', { table: props.report, type: command })
}
</script>
