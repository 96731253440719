<template>
  <svg
    :class="[{ disabled: !printLink }, { 'cursor-pointer': printLink }]"
    class="ml-2 cursor-default"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 10.4951V13.299C2 13.6862 2.33579 14 2.75 14H13.25C13.6642 14 14 13.6862 14 13.299V10.4951M8 1V10.1127M8 10.1127L11 7.30882M8 10.1127L5 7.30882"
      :stroke="printLink ? '#5477A9' : '#5477a969'"
      stroke-width="1.5"
      stroke-miterlimit="3.8637"
      stroke-linecap="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'LoadIcon',
  props: {
    printLink: {
      type: [Boolean, Object],
      default: false
    }
  }
}
</script>
